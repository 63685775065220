export default {
  init() {
    $('[data-vbg]').youtube_background();

    $('body').addClass('overflow');

    $('#navbarSupportedContent .nav-link').on('click', function() {
      $('.navbar-toggler')[0].click();
      $('body').removeClass('overflow');
    });

    $('.navbar-brand').on('click', function() {
      $('#homecontainer').removeClass('hide');
      $('#background-wrapper .overlay').addClass('green');
      $('body').addClass('overflow');
    });

    $('#uno').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      $('.sidebar').addClass('dark');
      $('#background-wrapper .overlay').addClass('green');
      $('#homecontainer').removeClass('hide');
      $('body').addClass('overflow');
    });

    $('#dos').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      $('.sidebar').removeClass('dark');
      $('#background-wrapper .overlay').removeClass('green');
      $('#homecontainer').addClass('hide');
      $('body').removeClass('overflow');
    });

    $('#tres').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      $('.sidebar').removeClass('dark');
      $('#background-wrapper .overlay').removeClass('green');
      $('#homecontainer').addClass('hide');
      $('body').removeClass('overflow');
    });

    $('#quattro').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      $('.sidebar').removeClass('dark');
      $('#background-wrapper .overlay').removeClass('green');
      $('#homecontainer').addClass('hide');
      $('body').removeClass('overflow');
    });
    
    $('#cinco').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      $('.sidebar').removeClass('dark');
      $('#background-wrapper .overlay').removeClass('green');
      $('#homecontainer').addClass('hide');
      $('body').removeClass('overflow');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
